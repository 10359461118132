/* Table - redesign */
.header {
    color: #6e6e6e;
    font-size: 12px;
}
.ag-header-cell-label {
  font-size: 15px;
  color: #232323;
}
.ag-group-value {
  font-size: 14px;
  color: #232323;
}
/* for the color of checkbox in table */
.ag-theme-material {
  --ag-material-accent-color :  #2f65d7;
  --ag-material-primary-color : white;
}
.ag-row .ag-cell { /* vertically center each cell */
  align-items: center;
}
.ag-theme-material .ag-cell {
    line-height: 15px;
    height: 100%;
 }
 /* to remove the line after column group header  */
.ag-theme-material .ag-header-row:not(:first-child) .ag-header-cell {
  border-top: 0px;
}
/* For aesthetics */
.ag-header-group-cell-no-group{
  display: none;
  }

/* cell editor padding  in align with normal cell padding*/
.ag-theme-material .ag-has-focus .ag-cell-inline-editing {
  padding-left: 23px;
  padding-right: 23px;
}
.ag-header-container .ag-header-row {
  background-color: #ffffff;
  color: #000000;
}
.ag-header-row-column {
    border-bottom: 2px solid #333333;
}

.ag-header-row-column-group {
    border-bottom: 2px solid white;
}

.ag-theme-material .ag-menu-separator {
  height: 1px;
}
.ag-theme-material .ag-menu-list {
  cursor: pointer;
  padding: 0px 0;
}

/* Download Modal - redesign */
.C-Dialog__container{
  box-shadow: 0 0px 25px 0px #000000;
}

.ag-search-image-container{
  height: 40px;
  width: 40px;
  border-radius: 5px 0px 0px 5px;
  border: 2px solid #eeeeee;
  float: left;
}

.ag-search-image-count-container{
  height: 40px;
  width: 100px;
  border-radius: 0px 5px 5px 0px;
  border: 2px solid #eeeeee;
  margin-left: 40px;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
}

.result-table {
  position: fixed;
  top: 160px;
  left: 7%;
  right: 20px;
  transition: height 200ms;
}

.edit-table {
  position: static;
  height: 350px;
  
}